<template>
  <vca-card>
    <h2>{{ $t("deposit.list.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <DepositTable />
    </vca-card>
  </vca-card>
</template>

<script>
import DepositTable from "@/components/finance/deposits/AsyncDepositTable";
export default {
  name: "DepositList",
  components: { DepositTable },
  created() {
    this.$store.commit("deposits/pg/route", "finances/deposit");
    this.$store.commit("deposits/pg/context", "takings/pg");
    this.$store.dispatch({
      type: "deposits/pg/request",
      data: { initial: true },
    });
    if (Object.keys(this.$route.query).length == 0) {
      window.history.pushState(
        "",
        "",
        this.toQuery(this.$store.state.takings.query)
      );
    } else {
      this.$store.commit("takings/query", this.$route.query);
    }
  },
  computed: {
    current: {
      get() {
        return this.$store.state.deposits.current;
      },
      set(value) {
        this.$store.commit("deposits/current", value);
      },
    },
  },
};
</script>
